import gql from 'graphql-tag'

export const SETTINGS_QUERY = 

  gql`query {
    contact: globalSet (handle:"contact") {
      ...on contact_GlobalSet{
        email: globalEmail
        address: globalAddress
        linkedIn: globalLinkedIn
      }
    }
    seo: globalSet  (handle:"seo") {
      ...on seo_GlobalSet{
          title: globalSeoTitle
          description: globalSeoDescription
      }
    }
    enquiryForm: formieForm(handle: "enquiry"){
      formHandle: handle
      formFields {
        id
        displayName
        handle
        defaultValue
        label
        placeholder
        autocomplete: prePopulate
        required
        conditions
        type: typeName
        ... on Field_SingleLineText {
          min
          max
        } 
        ... on Field_Dropdown {
          id
          displayName
          options {
            label
            value
            isDefault
            disabled
          }
          inputTypeName
        }
        ... on Field_MultiLineText {
            id
            displayName
            min
            max
            maxType
        }
        ...on Field_Checkboxes{
          id
          displayName
          options{
            ...on FieldOption{
              label
              value
              isDefault
              disabled
            }
          }
        }
        ...on Field_Radio{
        id
        displayName
        options{
          ...on FieldOption{
            label
            value
            isDefault
            disabled
          }
        }
      }
      }
      settings {
        successByline: submitActionMessageHtml
      }
    }
  }`;