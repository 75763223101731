<template>
  <template v-if="errorPage">
    <main> 
      <router-view :key="route.name"></router-view>
    </main>
  </template>

  <template v-else>
    <app-overlay/>
    <main class="fluid-container">
      <h1 v-if="seoTitle" class="seo-title">{{ seoTitle }}</h1>
      <component v-if="AsyncLayout" :is="AsyncLayout">
        <router-view :key="uniqueKey"></router-view>
      </component>
      <router-view v-else :key="uniqueKey"></router-view>
    </main>
    <app-footer></app-footer>
  </template>
</template>

<script>
import { useRoute } from 'vue-router';
import { useApplicationStore } from '/stores/application';
import { defineAsyncComponent, computed, shallowRef, watchEffect, markRaw } from 'vue';
import AppFooter from '/components/shared/AppFooter.vue'

export default {
  components: {
    AppFooter,
    AppOverlay: defineAsyncComponent(() => import('/components/shared/AppOverlay.vue'))
  },

  setup() {
    const route = useRoute();
    const store = useApplicationStore();
    const uniqueKey = computed(() => route.params.slug !== 'undefined' ? `${route.name}${route.params.slug}` : route.name);
    const errorPage = computed(() => route.name === 'error');

    // Use shallowRef for AsyncLayout
    const AsyncLayout = shallowRef(null);

    // Dynamically import layout component
    watchEffect(() => {
      const layoutName = route.meta.layout;
      if (layoutName) {
        AsyncLayout.value = markRaw(defineAsyncComponent(() => 
          import(`../../components/layouts/${layoutName}.vue`)
        ));
      } else {
        AsyncLayout.value = null;
      }
    });

    const seoTitle = computed(() => {
      const seoTitles = [
        { route: 'home', title: 'Precast Ventures' },
      ];

      const result = seoTitles.find(title => title.route === route.name);
      return result ? result.title : false;
    });

    return {
      route,
      errorPage,
      AsyncLayout,
      uniqueKey,
      seoTitle,
    };
  }
}
</script>