import { ref } from 'vue'

class SharedIntersectionObserver {
  constructor() {
    this.observer = null
    this.observedElements = new Map() // element -> callbacks map
    this.defaultOptions = {
      rootMargin: '100px 0px 0px 0px',
      threshold: 0
    }
  }

  init(options = {}) {
    if (!window.IntersectionObserver || this.observer) return

    this.observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          const callbacks = this.observedElements.get(entry.target)
          if (callbacks) {
            callbacks.forEach(cb => cb(entry.isIntersecting))
          }
        })
      },
      { ...this.defaultOptions, ...options }
    )
  }

  observe(element, callback, options = {}) {
    if (!this.observer) {
      this.init(options)
    }

    if (!this.observer) return

    if (!this.observedElements.has(element)) {
      this.observedElements.set(element, new Set())
      this.observer.observe(element)
    }

    this.observedElements.get(element).add(callback)
  }

  unobserve(element, callback) {
    if (!this.observer || !this.observedElements.has(element)) return

    const callbacks = this.observedElements.get(element)
    if (callback) {
      callbacks.delete(callback)
    }

    if (!callback || callbacks.size === 0) {
      this.observer.unobserve(element)
      this.observedElements.delete(element)
    }
  }

  disconnect() {
    if (this.observer) {
      this.observer.disconnect()
      this.observedElements.clear()
    }
  }
}

export const sharedObserver = new SharedIntersectionObserver()