<template>
  <default>
    <router-view></router-view>
  </default>
</template>

<script>
import { useApplicationStore } from '/stores/application'
import { useRoute } from 'vue-router';
import { ref, computed, onMounted, onUnmounted, watch } from 'vue';
import { useHead } from '@unhead/vue'
import { useQuery } from '@vue/apollo-composable'
import { SETTINGS_QUERY } from '~/gql/settings.js';
import Default from '/components/layouts/Default.vue';

export default {
  components: { Default },
  setup() {
    const store = useApplicationStore()
    const route = useRoute();
    const breakpoint = ref('');
    const title = ref('Precast Ventures');

    // Computed 
    const computedTitle = computed(() => {
      if (store.settingsLoaded){
        if (store.title !== '')
          return store.title;
        else if (route.meta.title !== '')
          return document.title
        else 
          return document.title;
        }
    });

    const computedDescription = computed(() => {
      if (store.settingsLoaded){
        if (store.description !== '')
          return store.description;
        else
          return store.defaultDescription
      }
    });


    // Methods
    const setup = () => {
      console.log('%cStrategy: https://www.brooksandboyd.co.nz', 'color: #000000; font-weight: bold;');
      console.log('%cCreative: Jayde Drumm', 'color: #000000; font-weight: bold;');
      console.log('%cCopy: https://thecopyroom.co.nz', 'color: #000000; font-weight: bold;');
      console.log('%cDevelopment: https://kichin.co', 'color: #000000; font-weight: bold;');
      console.log('–');
      breakpointCheck();
    };
    
    // Meta
      useHead({
        title: computedTitle,
        meta: [
          { name: 'description', content: computedDescription }      
        ]
        ,
      })

    const breakpointCheck = () => {
      let w = document.documentElement.clientWidth;
      let curBreakpoint = '';

      if (w <= 576) {
        curBreakpoint = 'xs';
      } else if (w > 576 && w <= 767) {
        curBreakpoint = 'sm';
      } else if (w >= 768 && w <= 991) {
        curBreakpoint = 'md';
      } else if (w >= 992 && w <= 1199){
        curBreakpoint = 'lg';
      } else if (w >= 1200 && w <= 1439) {
        curBreakpoint = 'xl';
      } else {
        curBreakpoint = 'xxl';
      }

      if (curBreakpoint !== breakpoint.value) {
        breakpoint.value = curBreakpoint
        store.setBreakpoint(curBreakpoint)
        console.log('Breakpoint : ' + curBreakpoint)
      }
    };

    const resize = (e) => {
      breakpointCheck();
    };

    // Settings Data
    const { onResult, onError, result, loading, error, startPolling } = useQuery(SETTINGS_QUERY)
  
    onError(queryResult => {
      console.error(error.graphQLErrors)
      console.error(error.networkError)
    })
    
    onResult(queryResult => {
      if (!queryResult.loading){        
        // Contact
        store.setGlobalEmail(queryResult.data.contact.email);
        store.setGlobalAddress(queryResult.data.contact.address);
        store.setGlobalLinkedIn(queryResult.data.contact.linkedIn);
        store.setEnquiryForm(queryResult.data.enquiryForm);
        store.setDefaultTitle(queryResult.data.seo.title);
        store.setDefaultDescription(queryResult.data.seo.description);
        store.setSettingsLoaded()
        //console.log('settings loaded')
      }
    })

    // Lifecycle Hooks
    onMounted(() => {
      setup();
      window.addEventListener("resize", resize);
    });

    onUnmounted(() => {
      window.removeEventListener("resize", resize);
    });

    // Return properties/methods for the template
    return {
      computedTitle,
      computedDescription,
      resize,
      breakpointCheck,
      result, loading, error, onResult, onError,
    };
  }
};
</script>