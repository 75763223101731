import { defineStore } from 'pinia';

export const useApplicationStore = defineStore('app', {
  id: 'app',
  state: () => ({
    breakpoint: '',
    loaded: false,
    pageLoaded: true,
    settingsLoaded: false,
    title: '',
    titleSuffix: ' | Precast Ventures',
    defaultTitle: '',
    description: '',
    defaultDescription: '',
    seoDetails: {},
    globalEmail: '',
    globalAddress: '',
    globalLinkedIn: '',
    enquiryForm: {},
    siteId: 1,
    formieEndPoint: '/actions/formie/submissions/submit',
    csrfToken: '',
    csrfTokenName: '',
    overlay: false,
    commentOverlay: false
  }),
  getters: {
  },
  actions: {
    setBreakpoint(val){
      this.breakpoint = val
    },
    setLoaded() {
      this.loaded = true
    },
    setLotsLoaded() {
      this.lotsLoaded = true
    },
    setPageLoaded(val){
      this.pageLoaded = val
    },
    setSettingsLoaded(){
      this.settingsLoaded = true
    },
    setTitle(val){
      this.title = val
    },
    setTitleSuffix(val){
      this.titleSuffix = val;
    },
    setDefaultTitle(val){
      this.defaultTitle = val;
    },
    setDescription(val){
      this.description = val;
    },
    setDefaultDescription(val){
      this.defaultDescription = val;
    },
    setSeoDetails(val){
      this.seoDetails = val;
    },
    setGlobalEmail(val){
      this.globalEmail = val;
    },
    setGlobalAddress(val){
      this.globalAddress = val;
    },
    setGlobalLinkedIn(val){
      this.globalLinkedIn = val;
    },
    setEnquiryForm(val){
      this.enquiryForm = val;
    },
    setCSRFToken(val){
      this.csrfToken = val
    },
    setCSRFTokenName(val){
      this.csrfTokenName = val
    },
    setSeoDetails(val){
      this.seoDetails = val;
    },
    setOverlay(val){
      this.overlay = val;
    },
    setCommentOverlay(val){
      this.commentOverlay = val;
    }
  },
})