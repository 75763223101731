export function registerSvgs(app) {
  // Import all SVGs from a directory
  const svgs = import.meta.glob('~/assets/img/*.svg', {
    eager: true,
    import: 'default'
  })

  Object.entries(svgs).forEach(([path, component]) => {
    // Get svg name from path
    // e.g., '/src/assets/arrow.svg' -> 'SvgArrow'
    const svgName = 'Svg' + path
      .split('/')
      .pop()
      .replace(/\.svg$/, '')
      .split('-')
      .map(s => s.charAt(0).toUpperCase() + s.slice(1))
      .join('')

    app.component(svgName, component)
  })
}