<template>
  <footer>
    
  </footer> 
</template>


<script>
  import { useRoute } from 'vue-router';
  import { computed, defineAsyncComponent } from 'vue';
  import { useApplicationStore } from '/stores/application';
  import { smoothScrollToTop } from '/utils/helpers.js';
  
  export default {
    components: {
      EnquireForm: defineAsyncComponent(() => import("~/components/shared/EnquireForm.vue")),
    },
    props: {},
    setup(props) {
      const appStore = useApplicationStore();
      const route = useRoute();
      const email = computed(() => appStore.globalEmail);
      const linkedIn = computed(() => appStore.globalLinkedIn);

      const scrollToTop = () => {
        smoothScrollToTop(document.documentElement)
      }

      return {
        scrollToTop,
        email,
        linkedIn,
      };
    }
  }
</script>

<style scoped lang="scss">
  footer {
    
  }
</style>