import { createRouter, createWebHistory } from 'vue-router';
import { useApplicationStore } from '/stores/application';


const routes = [
    {
        path: '/',
        name: 'home',
       // component: () => import('~/components/pages/Home.vue'), 
         component: () => import('~/components/pages/Holding.vue'), 
        meta: { title: 'Precast Structures' }
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'error',
        component: () => import('~/components/pages/Error.vue'),
        meta: {
            title: 'Error 404',
        }
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { left: 0, top: 0 };
        }
    }
});

router.beforeEach((to, from, next) => {
    const appStore = useApplicationStore();
    // reset meta for next page
    appStore.setPageLoaded(true)
    appStore.setTitle('')
    appStore.setDescription(appStore.defaultDescription)
    next();
});

export default router;