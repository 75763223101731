


<template>
  <router-link
    v-bind="$props"
    v-slot="slotProps"
    ref="linkRef"
    @mouseenter="handleHover"
    @mouseleave="handleMouseLeave"
    @touchstart="handleHover"
    @focus="handleHover">
    <slot v-bind="slotProps"></slot>
  </router-link>
</template>

<script setup>
import { ref, onMounted, onUnmounted, computed } from 'vue'
import { useRouter } from 'vue-router'
import { preloadCache } from '~/utils/preloadCache.js'
import { sharedObserver } from '~/utils/preloadSharedObserver.js'

const LOG_STATES = {
  LOADING: '🚀 Loading components',
  CANCELED: '🚫 Preload canceled',
  COMPLETE: '✅ Loading complete',
  CACHE_HIT: '📦 Cached!',
  ERROR: '❌ Loading failed',
  TIMEOUT: '⏱️ Loading timed out',
  IDLE: '🌐 Idle check',
  CACHE_PENDING: '⏳ Resuming previous load',
  IDLE_START: '🔄 Starting idle check',
  PRELOAD_TRIGGER: '⚡ Preload triggered'
}

// Component props with reasonable defaults
const props = defineProps({
  to: {
    type: [String, Object],
    required: true
  },
  preload: {
    type: Boolean,
    default: true
  },
  preloadDistance: {
    type: Number,
    default: 0
  },
  preloadTimeout: {
    type: Number,
    default: 1500
  },
  useIntersection: {
    type: Boolean,
    default: false
  },
  debug: {
    type: Boolean,
    default: false
  },
  preloadStrategy: {
    type: String,
    default: 'aggressive',
    validator: (value) => ['aggressive', 'conservative'].includes(value)
  },
  timeout: {
    type: Number,
    default: 5000
  }
})

// Core refs
const router = useRouter()
const linkRef = ref(null)
const resolvedRoute = computed(() => router.resolve(props.to))

// State flags
const isPreloading = ref(false)
const hasCanceled = ref(false)
const isHovering = ref(false)
const isIntersecting = ref(false)

// Control refs
const preloadTimeoutId = ref(null)
const currentAbortController = ref(null)
const handleIntersection = ref(null)


// Utility
const log = (state, extra = {}) => {
  if (!props.debug) return
  
  const cleanExtra = { ...extra }
  delete cleanExtra.route
  delete cleanExtra.timestamp
  
  // Add component identifier to all logs
  const prefix = `[${componentName.value}]`
  
  // Network idle state logging
  if (state === LOG_STATES.IDLE) {
    const isIdle = extra.status === 'network_idle'
    console.log(`${prefix} ${state} (${isIdle ? 'idle' : 'busy'})`)
    return
  }

  // Loading state with strategy
  if (state === LOG_STATES.LOADING) {
    console.log(`${prefix} ${state} (${extra.strategy})`)
    return
  }
  
  // Completion state logging
  if (state === LOG_STATES.COMPLETE) {
    const sourceMap = {
      'cache_hit_complete': '(from cache',
      'cache_hit_partial': '(partial cache',
      'fresh_load': '(fresh load'
    }
    const source = sourceMap[extra.source] || ''
    const duration = extra.duration ? `, ${extra.duration}ms)` : ''
    
    console.log(`${prefix} ${state}${source}${duration}`)
    return
  }

  // Idle check start logging
  if (state === LOG_STATES.IDLE_START) {
    console.log(`${prefix} ${state} (source: ${extra.source}, isHovering: ${extra.isHovering}, isIntersecting: ${extra.isIntersecting})`)
    return
  }

  // Default state logging with extra info
  const extraInfo = Object.keys(cleanExtra).length > 0 
    ? ` (${Object.entries(cleanExtra)
        .map(([k, v]) => k === 'duration' ? `${v}ms` : `${k}: ${v}`)
        .join(', ')})`
    : ''
  
  console.log(`${prefix} ${state}${extraInfo}`)
}

const isCached = (routeHref) => {
  const cacheStatus = preloadCache.getCacheStatus(routeHref)
  return cacheStatus.isLoaded
}

const componentName = computed(() => {
  const path = resolvedRoute.value.href
  return path.split('/').filter(Boolean).pop() || path
})

const isCurrentRoute = computed(() => {
  return resolvedRoute.value.href === router.currentRoute.value.href
})


// Network Checks
const prefetchDNS = () => {
  const link = document.createElement('link')
  link.rel = 'dns-prefetch'
  link.href = resolvedRoute.value.href
  document.head.appendChild(link)
}

const checkNetworkIdle = () => {
  const routeHref = resolvedRoute.value.href
  if (isCached(routeHref)) return true
  if (!('getEntriesByType' in performance)) return true
  
  const now = performance.now()
  const resources = performance.getEntriesByType('resource')
  
  // Early return if we find any active request
  for (const resource of resources) {
    const startTime = resource.startTime
    const endTime = startTime + resource.duration
    
    if ((now - startTime) < 1000 || resource.duration === 0 || now < endTime) {
      if (props.debug) {
        log(LOG_STATES.IDLE, { status: 'network_busy' })
      }
      return false
    }
  }

  if (props.debug) {
    log(LOG_STATES.IDLE, { status: 'network_idle' })
  }
  return true
}

const startIdleCheck = (source = 'hover') => {
  stopIdleCheck()

  if (isCurrentRoute.value) {
    if (props.debug) log(LOG_STATES.CACHE_HIT, { reason: 'Current route' })
    return
  }
  
  const routeHref = resolvedRoute.value.href
  if (isCached(routeHref)) {
    preloadComponent()
    return
  }

  // Add debouncing to reduce number of checks
  let lastCheck = Date.now()
  const minInterval = 100 // Minimum time between checks

  const checkInterval = setInterval(() => {
    if (!isHovering.value && !isIntersecting.value) {
      stopIdleCheck()
      return
    }

    const now = Date.now()
    if (now - lastCheck < minInterval) return
    
    lastCheck = now
    const isIdle = checkNetworkIdle()
    if (isIdle) {
      stopIdleCheck()
      preloadComponent()
    }
  }, 100)

  preloadTimeoutId.value = checkInterval
}

const stopIdleCheck = () => {
  if (preloadTimeoutId.value) {
    clearInterval(preloadTimeoutId.value)
    preloadTimeoutId.value = null
  }
}


// Core loading logic
const loadMainComponent = async (component, signal, strategy = props.preloadStrategy) => {
  try {
    if (signal.aborted) throw new DOMException('Aborted', 'AbortError')
    
    const mod = await component()
    
    if (signal.aborted) throw new DOMException('Aborted', 'AbortError')
    
    // Handle aggressive strategy
    if (strategy === 'aggressive') {
      const asyncComponents = Object.values(mod.default?.components || {})
        .filter(c => c?.__asyncLoader)
      
      if (asyncComponents.length > 0 && !signal.aborted) {
        // Start all loads simultaneously, but check abort first
        await Promise.all(asyncComponents.map(async comp => {
          if (signal.aborted) throw new DOMException('Aborted', 'AbortError')
          return comp.__asyncLoader()
        }))
      }
    }
    
    return !signal.aborted
  } catch (error) {
    if (props.debug) {
      log(LOG_STATES.ERROR, { 
        error: error.message })
    }
    throw error
  }
}

const loadComponentWithTimeout = async (component, signal, strategy) => {
  const loadPromise = loadMainComponent(component, signal, strategy)
  const timeoutPromise = new Promise((_, reject) => {
    setTimeout(() => reject(new Error('Preload timeout')), props.timeout)
  })
  
  try {
    const result = await Promise.race([loadPromise, timeoutPromise])
    return result
  } catch (error) {
    if (error.name === 'AbortError' && !hasCanceled.value) {
      if (props.debug) log(LOG_STATES.CANCELED, { reason: 'Aborted' })
      hasCanceled.value = true
    } else if (error.message === 'Preload timeout' && props.debug) {
      log(LOG_STATES.TIMEOUT)
    }
    throw error
  }
}


// Preload management
const preloadComponent = async (strategyOverride) => {
  if (!props.preload || isPreloading.value) return

  const routeHref = resolvedRoute.value.href
  const startTime = performance.now()
  const matchedRoute = resolvedRoute.value.matched[0]

  // If the component is already an object (loaded), we should consider it cached
  if (matchedRoute?.components?.default && typeof matchedRoute.components.default === 'object') {
    if (props.debug) {
      log(LOG_STATES.CACHE_HIT, { reason: 'Route already loaded' })
    }
    preloadCache.markAsLoaded(routeHref)
    return
  }

  // Otherwise proceed with normal loading logic for function components
  if (!matchedRoute?.components?.default || typeof matchedRoute.components.default !== 'function') {
    if (props.debug) {
      log(LOG_STATES.ERROR, { 
        error: 'Invalid route component',
        route: routeHref,
        componentType: typeof matchedRoute?.components?.default
      })
    }
    return
  }

  const cacheStatus = preloadCache.getCacheStatus(routeHref)
  
  if (cacheStatus.isLoaded) {
    if (props.debug) log(LOG_STATES.CACHE_HIT)
    return
  }
  
  if (cacheStatus.isPending) {
    await handlePendingLoad(routeHref, startTime)
    return
  }
  
  await startFreshLoad(strategyOverride)
}

const handlePendingLoad = async (routeHref, startTime) => {
  if (props.debug) log(LOG_STATES.CACHE_PENDING)
  isPreloading.value = true
  
  try {
    await preloadCache.waitForPending(routeHref)
    if (props.debug) {
      log(LOG_STATES.COMPLETE, {
        strategy: props.preloadStrategy,
        duration: Math.round(performance.now() - startTime),
        source: 'pending'
      })
    }
  } catch (error) {
    if (!hasCanceled.value) {
      await startFreshLoad()
    }
  } finally {
    isPreloading.value = false
  }
}

const startFreshLoad = async (strategyOverride) => {
  const routeHref = resolvedRoute.value.href
  const startTime = performance.now()
  const matchedRoute = resolvedRoute.value.matched[0]
  const activeStrategy = strategyOverride || props.preloadStrategy
  
  clearAll()
  isPreloading.value = true
  hasCanceled.value = false
  
  try {
    currentAbortController.value = new AbortController()
    const signal = currentAbortController.value.signal
    
    if (props.debug) log(LOG_STATES.LOADING, { strategy: activeStrategy })
    
    const result = await loadComponentWithTimeout(
      matchedRoute.components.default, 
      signal,
      activeStrategy
    )
    
    if (result) {
      const duration = Math.round(performance.now() - startTime)
      if (props.debug) {
        log(LOG_STATES.COMPLETE, {
          strategy: activeStrategy,
          duration,
          source: 'fresh_load'
        })
      }
      
      preloadCache.markAsLoaded(routeHref)
    }
  } catch (error) {
    if (!hasCanceled.value && props.debug) {
      log(LOG_STATES.ERROR, { error: error.message })
    }
  } finally {
    isPreloading.value = false
    clearAll()
  }
}


// Event handlers
const handleHover = () => {
  if (isHovering.value) return 
  isHovering.value = true
  startIdleCheck('hover')
}

const handleMouseLeave = () => {
  isHovering.value = false
  stopIdleCheck()

  // Immediately abort everything
  if (currentAbortController.value) {
    currentAbortController.value.abort()
    currentAbortController.value = null
  }
  
  if (preloadTimeoutId.value) {
    clearTimeout(preloadTimeoutId.value)
    preloadTimeoutId.value = null
  }
}


// Intersection Observer
const setupIntersectionObserver = () => {
  if (!props.useIntersection || 
      props.preloadDistance === undefined || 
      !window.IntersectionObserver) return

  const element = linkRef.value?.$el || linkRef.value
  if (!element) return

  // Store the handler reference so we can remove it later
  handleIntersection.value = (intersecting) => {
    isIntersecting.value = intersecting

    if (intersecting) {
      startIdleCheck('intersection')
    } else if (preloadTimeoutId.value && !isHovering.value) {
      stopIdleCheck()
    }
  }

  sharedObserver.observe(
    element, 
    handleIntersection.value,
    {
      rootMargin: `${props.preloadDistance}px 0px 0px 0px`,
      threshold: 0
    }
  )
}


// Cleanup utilities
const clearAll = () => {
  if (currentAbortController.value) {
    currentAbortController.value.abort()
    currentAbortController.value = null
  }
  
  stopIdleCheck()
}


// Lifecycle hooks
onMounted(() => {
  prefetchDNS()
  if (props.useIntersection) {
    setupIntersectionObserver()
  }
})

onUnmounted(() => {
  clearAll()
  
  // cleanup of shared observer
  const element = linkRef.value?.$el || linkRef.value
  if (element && handleIntersection.value) {
    sharedObserver.unobserve(element, handleIntersection.value)
  }
})
</script>