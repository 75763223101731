import { useApplicationStore } from '/stores/application';

export const fullUrl = (url) => {
  if (target !== undefined) {
    return 'https://***' + url
  }
}

export const formatUrl = (url) => {
  if (url !== undefined) {
    if (url.startsWith('https://')) {
      url = url.split('https://')[1]
    }
    if (url.startsWith('http://')) {
      url = url.split('http://')[1]
    }
    if (url.startsWith('www.')) {
      url = url.split('www.')[1]
    }
    if (url.includes('/')) {
      url = url.split('/')[0];
    }
    return url
  }
}

export const formatFileSize = (fileSizeInBytes) => {
  var i = -1;
  var byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
  do {
    fileSizeInBytes /= 1024;
    i++;
  } while (fileSizeInBytes > 1024);

  if (i === 0) {
    return Math.round(fileSizeInBytes) + byteUnits[i];
  } else {
    return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
  }
};


export const toKebab = (string) => {
  if (typeof string !== "string") {
    return string
  } else {
    string = string.toLowerCase()
    string = string.replace(/[^a-zA-Z0-9 ]/g, '-') // remove specials
    string = string.replace(/ /g, '-') // remove spaces
    string = string.replace(/-{1,}/g, '-') // remove multiple dashes
    return string
  }
}


export const toCamelCase = (str) => {
  return str.replace(/-([a-z])/g, (_, char) => char.toUpperCase());
}


export const formatTel = (number) => {
  if (number === undefined) {
    return false
  }

  if (number.startsWith('0800')) {
    return number
  }
  // number = number.replace(/\s/g, '')
  if (number.startsWith('0')) {
    number = number.substring(1)
  }
  number = '+64 ' + number
  return number
}


export const is = (breakpoint) => {
  return store.state.breakpoint === breakpoint;
}


export const setMeta = (entry) => {
  const store = useApplicationStore();
  console.log(entry)
  if (entry.seoTitle !== '' && entry.seoTitle !== null)
    store.setTitle(entry.seoTitle)
  else
    store.setTitle(entry.title)
  if (entry.seoDescription !== '' && entry.seoDescription !== null)
    store.setDescription(entry.seoDescription)
}


export const debounce = (fn, delay) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn(...args), delay);
  };
};


export const smoothScrollToTop = (element, offset = 0, duration = 500) => {
  if (element) {
    const startPosition = window.pageYOffset;
    const targetPosition = element.getBoundingClientRect().top + window.pageYOffset - offset;
    const distance = targetPosition - startPosition;
    let start = null;

    function step(timestamp) {
      if (!start) start = timestamp;
      const progress = timestamp - start;
      window.scrollTo(0, easeInOutCubic(progress, startPosition, distance, duration));
      if (progress < duration) {
        window.requestAnimationFrame(step);
      }
    }

    function easeInOutCubic(t, b, c, d) {
      t /= d / 2;
      if (t < 1) return c / 2 * t * t * t + b;
      t -= 2;
      return c / 2 * (t * t * t + 2) + b;
    }

    window.requestAnimationFrame(step);
  }
}

