// Vue and core libraries
import { createApp, provide, h } from "vue";
import { createPinia } from 'pinia'
import Axios from 'axios';

// Plugins and utilities
import { createHttpLink } from 'apollo-link-http';
import { DefaultApolloClient } from '@vue/apollo-composable'
import { ApolloClient, InMemoryCache } from '@apollo/client/core'

import Router from './router.js';
import { createHead } from '@unhead/vue'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import VueGtag from "vue-gtag";

// Components
import ImageHelper from '~/components/helpers/ImageHelper.vue';
import CraftImageHelper from '~/components/helpers/CraftImageHelper.vue';
import { registerSvgs } from '~/utils/registerSvgs.js'
import AppLink from '~/components/helpers/AppLink.vue'
import App from '~/components/App.vue';

// styles
import '~/assets/scss/base.scss';

// Axios setup
let axiosBaseDomain = import.meta.env.VITE_BASE_URL

Axios.defaults.baseURL = axiosBaseDomain;
let m = document.location.href.match(/\btoken=([^&]+)/);
let token = m ? m[1] : '';

Axios.defaults.withCredentials = true;

// Apollo setup
const httpLink = createHttpLink({
  uri: `${axiosBaseDomain}/api?token=${token}`
});
const cache = new InMemoryCache();
const apolloClient = new ApolloClient({
  cache,
  uri: `${axiosBaseDomain}/api?token=${token}`,
});

// Vue setup
const pinia = createPinia()
const head = createHead()

// App init
const app = createApp({
  setup () {
    provide(DefaultApolloClient, apolloClient);
    registerSvgs(app);
  },
  render: () => h(App),
})

// Register plugins
app.use(head)
app.use(pinia)
app.use(Router)
app.use(VueReCaptcha, { 
  siteKey: '6LdaC3EqAAAAAFTa0TG2G8JdtSTK0Y0d82sucR4w',
  loaderOptions: {
    autoHideBadge: true
  } 
})
app.use(VueGtag, {
  config: { id: "G-1DPJ0FHLZS" }
},Router)

// Register global components
app.component('image-helper', ImageHelper);
app.component('craft-image-helper', CraftImageHelper);
app.component('app-link', AppLink);

// Mount the app
app.mount('#app');
