/**
 * PreloadCache - Manages component preloading with LRU caching
 * Handles caching of preloaded routes and their loading states
 */
export class PreloadCache {
  constructor(options = {}) {
    // Core cache storage
    this._cache = new Map()
    this._pending = new Map()
    this._loadedComponents = new Set()
    this._lruQueue = []
    
    // Configuration
    this._maxSize = options.maxSize || 300
    this._debug = options.debug || false
    this._cacheLifetime = options.cacheLifetime || 30 * 60 * 1000 // 30 minutes

    this._memoryUsage = 0
    this._maxMemoryMB = options.maxMemoryMB || 50

    // Add periodic cleanup
    this._cleanupInterval = setInterval(() => this.cleanup(), 5 * 60 * 1000) // 5 minutes
  }

  // Cleanup and destroy
  destroy() {
    try {
        if (this._cleanupInterval) {
            clearInterval(this._cleanupInterval)
            this._cleanupInterval = null
        }

        this._cache.clear()
        this._pending.clear()
        this._loadedComponents.clear()
        this._lruQueue = []

        return true
    } catch (error) {
        this.log('❌', 'Failed to destroy cache', { error: error.message })
        return false
    }
  }

  // Debug logging helper
  log(emoji, message, data = {}) {
    if (!this._debug) return
    console.log(`${emoji} ${message}`, Object.keys(data).length ? data : '')
  }

  // Public API methods
  getCacheStatus(route) {
    return {
      inCache: this._cache.has(route),
      isPending: this._pending.has(route),
      isLoaded: this._loadedComponents.has(route),
      pendingPromise: this._pending.get(route)
    }
  }

  async waitForPending(route) {
    const pending = this._pending.get(route)
    if (!pending) return false
    
    try {
      await pending
      return true
    } catch {
      return false
    }
  }

  isFullyLoaded(route) {
    const isLoaded = this._loadedComponents.has(route)
    this.log('🔍', `Checking if ${route} is fully loaded:`, { isLoaded })
    return isLoaded
  }

  isPreloaded(route) {
    const status = {
      cached: this._cache.has(route),
      pending: this._pending.has(route),
      loaded: this._loadedComponents.has(route)
    }
    
    this.log('🔍', `Cache status for ${route}:`, status)
    return status
  }

  // Component loading state management
  markAsLoaded(route, componentPath) {
    this._loadedComponents.add(route)
    if (componentPath) {
      this._loadedComponents.add(componentPath)
    }
    this._updateLRU(route)
    this.log('✅', `Marked ${route} as loaded`)
  }

  markAsPreloaded(route) {
    if (this._loadedComponents.has(route)) {
      this.log('⏭️', `Skipping ${route} - already loaded`)
      return
    }
    
    this._addToCache(route)
    this.log('📥', `Marked ${route} as preloaded`)
  }

  markAsPending(route, promise) {
    if (this._loadedComponents.has(route)) {
      this.log('⏭️', `Skipping ${route} - already loaded`)
      return
    }

    this._pending.set(route, promise)
    this.log('⏳', `Marked ${route} as pending`)
    
    // Cleanup after promise resolves
    promise.finally(() => {
      this._addToCache(route)
      this._pending.delete(route)
      this.log('✅', `Completed pending load for ${route}`)
    })
  }

  // Cache maintenance methods
  cleanup() {
    const now = Date.now()
    const threshold = now - this._cacheLifetime

    // Sort items by priority (frequency of access and recency)
    const items = Array.from(this._cache.entries())
      .map(([route, data]) => ({
        route,
        timestamp: data.timestamp,
        accessCount: data.accessCount || 0,
        priority: (data.accessCount || 0) * 0.7 + (data.timestamp / now) * 0.3
      }))
      .sort((a, b) => a.priority - b.priority)

    // Remove lowest priority items first
    let cleaned = 0
    items.forEach(({ route, timestamp }) => {
      if (timestamp < threshold || cleaned < items.length * 0.2) {
        this._cache.delete(route)
        this._lruQueue = this._lruQueue.filter(r => r !== route)
        cleaned++
      }
    })

    this._checkMemoryUsage()
  }

  // Private helper methods
  _addToCache(route) {
    if (this._loadedComponents.has(route)) return

    const existing = this._cache.get(route)
    this._cache.set(route, {
      timestamp: Date.now(),
      accessCount: (existing?.accessCount || 0) + 1
    })

    this._updateLRU(route)
    this.log('📥', `Added ${route} to cache`)

    this._checkMemoryUsage()
  }

  _updateLRU(route) {
    const index = this._lruQueue.indexOf(route)
    if (index > -1) {
      this._lruQueue.splice(index, 1)
    }
    this._lruQueue.push(route)
  }

  _checkMemoryUsage() {
    if ('performance' in window && performance.memory) {
      const usedHeapSize = performance.memory.usedJSHeapSize / (1024 * 1024)
      if (usedHeapSize > this._maxMemoryMB) {
        this._forceCacheCleanup()
      }
    }
  }

  _forceCacheCleanup() {
    // Keep only the most recently used 20% of items
    const keepCount = Math.ceil(this._cache.size * 0.2)
    const itemsToRemove = this._lruQueue.slice(0, -keepCount)

    itemsToRemove.forEach(route => {
      this._cache.delete(route)
      this._loadedComponents.delete(route)
    })

    this._lruQueue = this._lruQueue.slice(-keepCount)
  }
}

// Export singleton instance
export const preloadCache = new PreloadCache()